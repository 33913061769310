export const errorTypes = {
  NO_CONNECTION: {
    CODE: '0000',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  ASSETS_ERROR: {
    CODE: '0001',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  SOCKET_DISCONNECTED: {
    CODE: '0002',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  SOCIAL_FALLBACK_NOT_SPECIFIED: {
    CODE: '0003',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  INSUFFICIENT_BALANCE_CLIENT: {
    CODE: '9001',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: false,
  },
  SESSION_EXPIRED: {
    CODE: 2401,
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  SESSION_BUSY: {
    CODE: 2402,
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  INSUFFICIENT_BALANCE: {
    CODE: 9001,
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  MAX_BET_LIMIT: {
    CODE: 9002,
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
};
